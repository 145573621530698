import React from "react";
import { BodyText } from "./texts/BodyText";
import { heading } from "../constants/fonts";

interface IHeadParaProps {
  para?: string;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
}

const HeadPara: React.FC<IHeadParaProps> = ({ para, large, medium, small }) => {
  var fontSize = { base: "2.25rem", lg: "3.5rem" };

  if (medium) fontSize = { base: "1.8rem", lg: "2rem" };
  else if (small) fontSize = { base: "0.5rem", lg: "1.5rem" };

  return (
    <BodyText
      marginBottom="1rem"
      fontFamily={heading}
      fontSize={fontSize}
      fontWeight="500"
      lineHeight="1"
    >
      {para}
    </BodyText>
  );
};

export default HeadPara;
