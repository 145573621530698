import { Box, Grid, Link, Stack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "./texts/Hero";
import { BodyText } from "./texts/BodyText";

interface ArticleProp {
  tag?: string;
  value?: string;
}

const ArticleTag: React.FC<ArticleProp> = ({ tag, value }) => {
  return (
    <BodyText marginTop="0.6rem" style={{ fontSize: "18px" }}>
      <strong>{tag} |</strong> {value}
    </BodyText>
  );
};

export default ArticleTag;
