import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IAlignmentContainerProps extends BoxProps {
  children?: ReactNode;
}

const AlignmentContainer: React.FC<IAlignmentContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="center"
      marginLeft={{ base: "1rem", lg: "7rem" }}
      marginRight={{ base: "1rem", lg: "7rem" }}
      paddingLeft="0.5rem"
      paddingRight="0.5rem"
      gap="1rem"
      {...props}
    >
      {children}
    </Box>
  );
};

export default AlignmentContainer;
