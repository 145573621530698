import {
  Box,
  BoxProps,
  Container,
  Grid,
  Link,
  LinkBox,
  Stack,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IArticalImageProps extends BoxProps {
  children?: ReactNode;
  body?: string;
}

const ArticleImageContainer: React.FC<IArticalImageProps> = ({
  children,
  ...props
}) => {
  return (
    <Box marginTop="5rem" {...props}>
      {children}
    </Box>
  );
};

export default ArticleImageContainer;
