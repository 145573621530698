import React from "react";
import { BodyText, BodyTextProps } from "./texts/BodyText";

interface Props extends BodyTextProps {
  text?: string;
}

const ImageText: React.FC<Props> = (props) => {
  const { text, ...rest } = props;
  return (
    <BodyText
      marginTop="1rem"
      style={{ fontSize: "0.875rem", fontStyle: "italic" }}
      {...rest}
    >
      {text}
    </BodyText>
  );
};

export default ImageText;
