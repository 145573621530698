import { Box, Flex, FlexProps, Link, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BodyText } from "../texts/BodyText";
import { Subtitle } from "../texts/Subtitle";

export interface ContactCardProps extends FlexProps {
  image: ReactNode;
  name: string;
  title: string;
  emailAddress: string;
  phoneNumber?: string;
  linkedIn?: string;
  bottomText?: string;
  location?: { name: string; location: string };
  isVertical?: boolean;
}

export function ContactCard(props: ContactCardProps) {
  const {
    isVertical,
    image,
    name,
    title,
    emailAddress,
    phoneNumber,
    location,
    linkedIn,
    bottomText,
    ...chakra
  } = props;

  return (
    <Flex
      {...chakra}
      maxW="500px"
      alignItems="center"
      flexGrow={1}
      flexDir={{
        base: "column",
        lg: isVertical ? "column" : "row",
      }}
    >
      <Box maxW="250px">{image}</Box>
      <Box
        ml={{
          lg: isVertical ? "0" : "8",
        }}
        mt={{
          base: "8",
          lg: isVertical ? "8" : "0",
        }}
        textAlign={{
          base: "center",
          lg: isVertical ? "center" : "start",
        }}
      >
        <Subtitle>{name}</Subtitle>
        <BodyText
          mt="1"
          textTransform="uppercase"
          letterSpacing="0.09375rem"
          bold
        >
          {title}
        </BodyText>
        <BodyText mt="4">
          <Link display="block" href={`mailto:${emailAddress}`}>
            {emailAddress}
          </Link>
          {phoneNumber && (
            <Link display="block" href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </Link>
          )}
          {location && (
            <Link display="block" href={location.location} target="_blank">
              {location.name}
            </Link>
          )}
          {linkedIn && (
            <Link display="block" href={linkedIn} target="_blank">
              LinkedIn
            </Link>
          )}
          {bottomText && (
            <strong style={{ fontWeight: 100 }}>{bottomText}</strong>
          )}
        </BodyText>
      </Box>
    </Flex>
  );
}
