import React from "react";
import { BodyText } from "./texts/BodyText";

interface ArticleProp {
    para?: string;
}

const PicturePara: React.FC<ArticleProp> = ({
    para
}) => {
    return (
        <BodyText marginBottom="16px">
            {para}
        </BodyText>
    );
};

export default PicturePara;
