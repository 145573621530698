import React from "react";
import { Text, TextProps } from "@chakra-ui/react"
import { heading } from "../../constants/fonts";

export function Subtitle(props: TextProps) {

  return (
    <Text
      as="h2"
      fontFamily={heading}
      fontSize={{
        base: "1.25rem",
        lg: "1.625rem"
      }}
      fontWeight="500"
      lineHeight="1"
      {...props}
    >
    </Text >
  )
}