import { FlexProps } from "@chakra-ui/react";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ContactCard } from "./ContactCard";


export function ContactMaricaCard(props: FlexProps) {
  return (
    <ContactCard
      image={
        <StaticImage
          src="../../../images/marica.jpg"
          alt="Marica"
          placeholder="blurred"
        />
      }
      name={"Marica Öst"}
      title={"Project manager"}
      emailAddress={"marica.ost@indevit.fi"}
      phoneNumber={"+358 401801482"}
      linkedIn={"https://www.linkedin.com/in/marica-%C3%B6st-304319200/"}
      {...props}
    />
  );
}
