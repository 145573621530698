import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Section } from "../../../layout/Section";
import ArticleHeading from "../../../ArticleHeading";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import PicturePara from "../../../PicturePara";
import HeadPara from "../../../HeadPara";
import { ContactCard } from "../../../cards/ContactCard";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import ArticleTag from "../../../ArticleTag";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";

interface InsightSectionProps {}

const EntiaSection: React.FC<InsightSectionProps> = () => {
  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <AlignmentContainer>
          <ArticleHeading subtitle="Entia and Indevit's integration success " />
          <ArticleTags>
            <ArticleTag tag="Customer" value="Entia" />
            <ArticleTag tag="Project" value="Integration" />
            <ArticleTag tag="What we worked on" value="Systems, Invoicing" />
          </ArticleTags>
          <BodyText>
            Founded in 2007, Entia, led by Ronnie Ollila, provides high-quality
            IT services. Facing challenges with manual invoicing, they teamed up
            with Indevit to streamline operations and integrate their systems -
            Visma Nova (accounting) and Connectwise PSA (ERP).
          </BodyText>
        </AlignmentContainer>
        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/indevit-entia-1.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText
              text="Entia, led by Ronnie Ollila, teamed up with 
                Indevit to streamline operations and integrate their systems."
            />
          </AlignmentContainer>
        </ArticleImageContainer>
        <AlignmentContainer>
          <HeadPara medium para="The integration process" />
          <PicturePara
            para="As Entia's workload grew, so did the need for automation. Manual processes were time-consuming, prompting the decision to update their ERP system for smoother internal operations. To meet the challenge, Entia collaborated with Indevit due to their approachability and direct communication. 
        Indevit's straightforward approach and flexible requirements made them a perfect match for Entia."
          />
          <PicturePara
            para="Entia used Visma Nova and Connectwise PSA separately. Indevit bridged the gap by creating an integration that allowed seamless data transfer between the two systems.
         Regular meetings and effective teamwork ensured the project's completion on time."
          />
        </AlignmentContainer>
        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/indevit-entia-2.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Regular meetings and effective teamwork ensured the project's completion on time." />
          </AlignmentContainer>
        </ArticleImageContainer>
        <AlignmentContainer>
          <HeadPara medium para="The results" />
          <PicturePara
            para="The integration significantly sped up Entia's invoicing procedures, saving them considerable time. Automation from Connectwise streamlined processes, resulting in measurable efficiency improvements. Closeness and shared language (Swedish) facilitated a comfortable working environment.
         Smooth communication played a crucial role in building a positive working relationship."
          />
          <PicturePara
            para="Indevit approached the integration with readiness to assist, maintaining simple and close communication. Despite no prior knowledge of the systems, collaboration was smooth, thanks to Entia's detailed information. Indevit chose a tailored solution after consulting with Entia, prioritizing cost-effectiveness.
         Utilizing Entia's existing infrastructure minimized costs, highlighting a strategic and economical approach."
          />
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/indevit-entia-3.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Smooth communication played a crucial role in building a positive working relationship." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Cooperation experience" />
          <PicturePara
            para="The cooperation between Entia and Indevit was marked by effective communication, thorough preparation, and a shared understanding of project requirements. Entia's solid knowledge of their own databases facilitated a fun and successful integration experience. In conclusion, Entia's integration journey with Indevit exemplifies the positive outcomes that arise from strategic collaboration, 
        effective communication, and a commitment to meeting the unique needs of the client."
          />
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};

export default EntiaSection;
