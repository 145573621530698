import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { EntiaHero } from "../components/sections/insight/EntiaAndIndevit/EntiaHero";
import EntiaSection from "../components/sections/insight/EntiaAndIndevit/EntiaSection";

function InsightEntiaPage() {
  return (
    <Layout colorScheme="secondary" heroSection={<EntiaHero />} isHeroShortBaseOnImage isWhite>
      <EntiaSection />
    </Layout>
  )
}

export default InsightEntiaPage;

export const Head = () => (
  <MetaTags title="Entia and Indevit's integration success" />
)