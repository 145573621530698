import React from "react";
import { Box } from "@chakra-ui/react";
import { Hero } from "./texts/Hero";

interface ArticleProp {
    subtitle?: string;
}

const ArticleHeading: React.FC<ArticleProp> = ({
    subtitle,
}) => {
    return (
        <Hero>
            {subtitle}
        </Hero>
    );
};

export default ArticleHeading;
